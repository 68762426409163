import { FormattedMessage } from 'react-intl'
import * as Pages from 'layouts/pages'
import { ButtonSet } from 'components/button'

export default function Custom404() {
    return (
        <Pages.Error>
            <h1>
                <FormattedMessage
                    description="Layout pages/NotFound"
                    defaultMessage="This is an avalanche size 404 error..."
                />
                <small>
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="The page you are looking for has not been found."
                    />
                </small>
            </h1>
            <ButtonSet>
                <Pages.Link href="/">
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="Forecasts"
                    />
                </Pages.Link>
                <Pages.Link href="/training">
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="Training"
                    />
                </Pages.Link>
                <Pages.Link href="/news">
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="Latest news"
                    />
                </Pages.Link>
                <Pages.Link href="/events">
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="Upcoming events"
                    />
                </Pages.Link>
                <Pages.Link href="/blogs">
                    <FormattedMessage
                        description="Layout pages/NotFound"
                        defaultMessage="Our blog"
                    />
                </Pages.Link>
            </ButtonSet>
        </Pages.Error>
    )
}
